import React from "react"
import AboutUsServicesSectionStyle from "./AboutUsServicesSectionStyle.module.styl"
import Slider from "react-slick"
import ServiceSliderCard from "../../../common/packages/serviceSliderCard/ServiceSliderCard"
import SODImage from "./../../../assets/images/Rectangle 62@2x-min.png"
import EventsImage from "./../../../assets/images/Rectangle 63@2x-min.png"
import LandImage from "./../../../assets/images/Rectangle 60@2x-min.png"
import SecurityGuardsImage from "./../../../assets/images/Rectangle 59@2x-min.png"
import VideoImage from "./../../../assets/images/Rectangle 61@2x-min.png"

const settings = {
  dots: true,
  infinite: false,
  speed: 500,
  slidesToShow: 3,
  slidesToScroll: 1,
  responsive: [
    {
      breakpoint: 992,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
}
const AboutUsServicesSection = props => {
  return (
    <section className={AboutUsServicesSectionStyle.about_us_services_section}>
      <div className={AboutUsServicesSectionStyle.about_us_services_content}>
        <h2>Нашите услуги</h2>
        <Slider {...settings}>
          <ServiceSliderCard
            service_image={SODImage}
            service_name={"СОД"}
            service_url={"/signalno-ohranitelna-deinost"}
          />
          <ServiceSliderCard
            service_image={EventsImage}
            service_name={"Охрана на мероприятия"}
          />
          <ServiceSliderCard
            service_image={LandImage}
            service_name={"Охрана на земеделски площи"}
          />
          <ServiceSliderCard
            service_image={SecurityGuardsImage}
            service_name={"Физическа охрана"}
          />
          <ServiceSliderCard
            service_image={VideoImage}
            service_name={"Видеонаблюдение"}
          />
        </Slider>
      </div>
    </section>
  )
}

export default AboutUsServicesSection
