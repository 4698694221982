import React from "react"
import AboutUsHeroSectionStyle from "./AboutUsHeroSectionStyle.module.styl"
import Img from "gatsby-image"

const AboutUsHeroSection = ({ aboutUsHeroSectionImage }) => {
  return (
    <section className={AboutUsHeroSectionStyle.hero_section}>
      <Img
        className={AboutUsHeroSectionStyle.image}
        fluid={aboutUsHeroSectionImage[0].node.childImageSharp.fluid}
      />
      <div className={AboutUsHeroSectionStyle.title_wrapper}>
        <h1>За Special Security Group</h1>
      </div>
    </section>
  )
}

export default AboutUsHeroSection
