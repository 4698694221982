import React from "react"
import Layout from "../common/layout/layout"
import SEO from "../common/seo/seo"
import { graphql } from "gatsby"
import AboutUsTemplate from "../modules/aboutUs/AboutUs.template"

const AboutUsPage = ({ data }) => {
  return (
    <Layout>
      <SEO
        title={"За нас"}
        keywords={
          "СОТ Ямбол, охрана Ямбол, СОД ямбол, Видеонаблюдение,охрана на обекти Ямбол, физическа охрана Ямбол, цени на СоТ Ямбол"
        }
      />
      <AboutUsTemplate
        aboutUsHeroSectionImage={data.aboutUsHeroSectionImage.edges}
        aboutUsIntroImage={data.aboutUsIntroImage.edges}
        aboutUsMoreInfoImage={data.aboutUsMoreInfoImage.edges}
      />
    </Layout>
  )
}

export default AboutUsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    aboutUsHeroSectionImage: allFile(
      filter: { relativeDirectory: { eq: "aboutUsHeroSectionImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 1500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    aboutUsIntroImage: allFile(
      filter: { relativeDirectory: { eq: "aboutUsIntroImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
    aboutUsMoreInfoImage: allFile(
      filter: { relativeDirectory: { eq: "aboutUsMoreInfoImage" } }
    ) {
      edges {
        node {
          id
          childImageSharp {
            fluid(maxWidth: 500, quality: 100) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`
