import React from "react"
import AboutUsHeroSection from "./aboutUsHeroSection/AboutUsHeroSection"
import AboutUsIntroSection from "./aboutIsIntroSection/AboutUsIntroSection"
import AboutUsMoreInfoSection from "./aboutUsMoreInfoSection/AboutUsMoreInfoSection"
import AboutUsServicesSection from "./aboutUsServicesSection/AboutUsServicesSection"

const AboutUsTemplate = ({
  aboutUsHeroSectionImage,
  aboutUsIntroImage,
  aboutUsMoreInfoImage,
}) => {
  return (
    <>
      <AboutUsHeroSection aboutUsHeroSectionImage={aboutUsHeroSectionImage} />
      <AboutUsIntroSection aboutUsIntroImage={aboutUsIntroImage} />
      <AboutUsMoreInfoSection aboutUsMoreInfoImage={aboutUsMoreInfoImage} />
      <AboutUsServicesSection/>
    </>
  )
}

export default AboutUsTemplate
