import React from "react"
import AboutUsIntroSectionStyle from "./AboutUsIntroSectionStyle.module.styl"
import Img from "gatsby-image"

const AboutUsIntroSection = ({ aboutUsIntroImage }) => {
  return (
    <section className={AboutUsIntroSectionStyle.intro_section}>
      <div className={AboutUsIntroSectionStyle.intro_section_content}>
        <Img className={AboutUsIntroSectionStyle.intro_img} fluid={aboutUsIntroImage[0].node.childImageSharp.fluid} />
        <div className={AboutUsIntroSectionStyle.intro_text}>
          <span>"Спешъл Сикюрити Груп" ООД</span> е фирма, основана с идея и цел
          да предложи на пазара за охрана, иновативни методи за охрана и
          видеонаблюдение, чрез най-съвременната техника и ноу-хау. Служителите
          на „Спешъл Сикюрити Груп“ са преминали професионално обучение, което
          гарантира най-високо качество на предлаганите услуги. Чрез
          ефективност, солидарност и гъвкавост, ние изграждаме един бранд, който
          е гаранция за коректно партньорство в охранителният бизнес.
        </div>
      </div>
    </section>
  )
}

export default AboutUsIntroSection
