import React from "react"
import AboutUsMoreInfoSectionStyle from "./AboutUsMoreInfoSectionStyle.module.styl"
import Img from "gatsby-image"
import RectangleImage from "./../../../assets/images/Rectangle 67.svg"

const AboutUsMoreInfoSection = ({ aboutUsMoreInfoImage }) => {
  return (
    <section className={AboutUsMoreInfoSectionStyle.rectangle_wrapper}>
      <div className={AboutUsMoreInfoSectionStyle.more_info_section}>
        <div className={AboutUsMoreInfoSectionStyle.more_info_content}>
          <div className={AboutUsMoreInfoSectionStyle.more_info}>
            Днес <span>"Спешъл Сикюрити Груп"</span> предлага пълната гама
            услуги, които включват охрана за физически лица и корпоративни
            клиенти, жива охрана на търговски, производствени и частни обекти,
            земеделски и животновъдни обекти, пожароизвестяване, СОТ, СОД чрез
            камери и видеомониторинг. Оборудването и автомобилният парк са
            изграждат с постоянни инвестиции и обновление, за да предоставим
            спокойствие и удовлетвореност на нашите клиенти.
          </div>
          <Img
            className={AboutUsMoreInfoSectionStyle.more_info_image}
            fluid={aboutUsMoreInfoImage[0].node.childImageSharp.fluid}
          />
        </div>{" "}
      </div>{" "}
      <div className={AboutUsMoreInfoSectionStyle.rectangle_img_holder}>
        <img
          className={AboutUsMoreInfoSectionStyle.rectangle}
          src={RectangleImage}
          alt={"rectangle"}
        />
      </div>
    </section>
  )
}

export default AboutUsMoreInfoSection
